/**
 * Transforma um valor booleano para sim/não
 * @param booleanValue
 * @return {string}
 * @constructor
 */
export function yesNoRepresentation (booleanValue) {
  return !!booleanValue ? 'Sim' : 'Não'
}

/**
 * Transforma um valor booleano para ativo/inativo
 * @param booleanValue
 * @return {string}
 */
export function activeInactiveRepresentation (booleanValue) {
  return !!booleanValue ? 'Ativo' : 'Inativo'
}